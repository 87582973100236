













































import { Component, Prop, Vue } from "vue-property-decorator";
import Layout from "@/components/Layout.vue"; // @ is an alias to /src
import AssignCard from "@/components/common/AssignCard.vue";
import MainSlogan from "@/components/common/MainSlogan.vue";
import WordOnPic from "@/components/common/WordOnPic.vue";
import PicWall from "@/components/common/PicWall.vue";
@Component({
  components: {
    Layout,
    AssignCard,
    MainSlogan,
    WordOnPic,
    PicWall,
  },
})
export default class HomeEnergy extends Vue {
  data() {
    return {
      bg: require("@/assets/storage/img/bg.png"),
      stwo: require("@/assets/storage/img/stwo.png"),
      sthree: require("@/assets/storage/img/sthree.png"),
      sfour: require("@/assets/storage/img/sfour.png"),
      sfive: require("@/assets/storage/img/sfive.png"),
    };
  }
  goSolar() {
    this.$router.push({
      path: "/clean/homeenergy/solar",
    });
  }
  goPowerwall() {
    this.$router.push({
      path: "/clean/homeenergy/powerwall",
    });
  }
}
